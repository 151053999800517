<template>
    <div class="collapse-panels mt-5 lg:mt-8 mb-8 lg:mb-12">
        <div class="collapse-panels__container container mx-auto px-container lg:px-container-lg">
            <h2 v-if="heading" class="text-brand-purple text-center">{{ heading }}</h2>

            <div v-if="panels" class="collapse-panels__content w-full lg:w-8/12 mx-auto mt-1 lg:mt-3">
                <div class="collapse-panels__panel py-5 border-b border-solid border-grey-medium flex cursor-pointer" v-for="(panel, key, index) in panels" :key="'panel' + index" @click="togglePanel">
                    <div class="collapse-panels__arrow arrow-s flex-shrink-0 mr-2"></div>
                    <div>
                        <h4 v-if="panel.question" class="text-brand-purple">{{ panel.question }}</h4>
                        <div class="collapse-panels__answer-container h-0 overflow-hidden">
                            <div v-if="panel.answer" class="collapse-panels__answer text-grey-dark pt-1" v-html="panel.answer"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="collapse-panels__paragraph mt-5 lg:mt-8">
                <h4 class="text-brand-purple text-center" v-html="paragraph"></h4>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'collapse-panels',

        props : {
            heading : { type : String, default : ''},
            paragraph : { type: String, default : ''},
            panels : { type : Array, default : () => []}
        },

        mounted(){
            window.addEventListener('resize', this.resizeHandler);
        },

        beforeDestroy(){
            window.removeEventListener('resize', this.resizeHandler);
        },

        methods: {
            togglePanel(pEvent){
                let target = (pEvent.target.classList.contains('collapse-panels__panel')) ? pEvent.target : this.parents(pEvent.target, '.collapse-panels__panel')[0];

                TweenLite.to(target.querySelector('.collapse-panels__arrow'), 0.3, {
                    rotation :  (target.querySelector('.collapse-panels__answer-container').classList.contains('opened')) ? '0deg' : '90deg'
                });

                TweenLite.to(target.querySelector('.collapse-panels__answer-container'), 0.5, {
                    height : (target.querySelector('.collapse-panels__answer-container').classList.contains('opened')) ? 0 : target.querySelector('.collapse-panels__answer').clientHeight + 8,
                    ease : Expo.easeOut,
                    onComplete : () => {
                        TweenLite.set(target.querySelector('.collapse-panels__answer-container'), {
                            className : (target.querySelector('.collapse-panels__answer-container').classList.contains('opened')) ? '-=opened' : '+=opened'
                        })
                    }
                });
            },

            parents( elem, selector ) {

                // Element.matches() polyfill
                if (!Element.prototype.matches) {
                    Element.prototype.matches =
                        Element.prototype.matchesSelector ||
                        Element.prototype.mozMatchesSelector ||
                        Element.prototype.msMatchesSelector ||
                        Element.prototype.oMatchesSelector ||
                        Element.prototype.webkitMatchesSelector ||
                        function(s) {
                            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                                i = matches.length;
                            while (--i >= 0 && matches.item(i) !== this) {}
                            return i > -1;
                        };
                }

                // Setup parents array
                var parents = [];

                // Get matching parent elements
                for ( ; elem && elem !== document; elem = elem.parentNode ) {

                    // Add matching parents to array
                    if ( selector ) {
                        if ( elem.matches( selector ) ) {
                            parents.push( elem );
                        }
                    } else {
                        parents.push( elem );
                    }

                }

                return parents;

            },

            resizeHandler(){
                Array.prototype.forEach.call(document.querySelectorAll('.collapse-panels__answer-container.opened'), (pEl, pI) => {

                    TweenLite.set(pEl, {
                        height : pEl.querySelector('.collapse-panels__answer').clientHeight + 8,
                    });
                });

            }
        }
    }
</script>

<style lang="scss">
    .collapse-panels {
        .arrow-s {
            margin-top: 3px;
        }
    }
</style>

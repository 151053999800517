<template>
    <div class="carousel relative overflow-hidden mt-8 lg:mt-10 pb-3 mb-2">
        <div class="carousel__container container relative mx-auto px-container lg:px-container-lg">
            <h6 class="text-center text-brand-blue uppercase">{{ heading }}</h6>
            <h2 class="text-center text-brand-purple">{{ slideHeading }}</h2>
            <div ref="slidesContainer" class="carousel__slides-container flex mt-5 lg:mt-4" v-touch:swipe="swipeHandler">
                <div ref="slide" class="carousel__slide w-11/12 lg:w-full bg-white shadow-medium flex-shrink-0 flex flex-col lg:flex-row-reverse p-2 pb-4 lg:px-7 lg:py-12" v-for="(item, index) in slides" :key="'slide'+ index">
                    <div class="carousel__slide-image w-full flex justify-center lg:w-auto lg:ml-8">
                        <a v-if="item.button && item.imageDesktop" :href="item.button.url" :target="item.button.target" class="mt-3 lg:mt-4">
                            <picture v-if="item.imageDesktop">
                                <source v-if="item.imageMobile" media="(max-width : 991px)" :data-srcset="item.imageMobile.url"/>
                                <source media="(min-width : 992px)" :data-srcset="item.imageDesktop.url"/>
                                <img :src="item.imageDesktop.url" :alt="item.imageDesktop.alt" class="w-full" loading="eager"/>
                            </picture>
                        </a>
                        <picture v-if="item.imageDesktop && !item.button">
                            <source v-if="item.imageMobile" media="(max-width : 991px)" :data-srcset="item.imageMobile.url"/>
                            <source media="(min-width : 992px)" :data-srcset="item.imageDesktop.url"/>
                            <img :src="item.imageDesktop.url" :alt="item.imageDesktop.alt" class="w-full" loading="eager"/>
                        </picture>
                    </div>
                    <div class="carousel__slide-text max-w-1/2 lg:w-1/2 mt-2 lg:mt-7">
                        <h4 class="text-brand-purple">{{ item.subheading }}</h4>
                        <p class="text-grey-dark mt-1 lg:mt-2" v-html="item.text"></p>
                        <a v-if="item.button" :href="item.button.url" :target="item.button.target" class="btn btn--blue mt-3 lg:mt-4">{{ item.button.label}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="carousel__arrow-container relative">
            <div v-if="currentIndex > 0" class="carousel__arrow-left arrow-blue absolute hidden lg:block" @click="currentIndex--"></div>
            <div v-if="currentIndex < slideCount - 1" class="carousel__arrow-right arrow-blue absolute hidden lg:block" @click="currentIndex++"></div>
        </div>
        <div v-if="slideCount > 1" class="carousel__dots flex justify-center mt-4 lg:mt-5">
            <div class="carousel__dot" :class="{'bg-brand-blue' : currentIndex === index, 'bg-grey-medium' : currentIndex != index}" v-for="(item, index) in slides" :key="'dot' + index" @click="currentIndex = index"></div>
        </div>
    </div>
</template>

<script>
    import Carousel from '../mixins/Carousel';

    export default {
        name : 'carousel',

        props:  {
            heading : { type : String, default : ''},
            slides : { type : Array, default : () => {}}
        },

        mixins : [Carousel],

        data(){
            return {
                currentIndex : 0
            }
        },

        computed: {
            slideHeading() {
                return this.slides[this.currentIndex].heading;
            }
        },
    }
</script>

<style lang="scss">
    .carousel {
        &__slide {
            box-sizing: border-box;

            &:not(:first-child) {
                @apply ml-2;

                @screen lg{
                    margin-left: 25vw;
                }
            }
        }

        &__slide-image {
            @screen lg {
                max-width: 50%;
            }
        }

        &__dot {
            width: 12px;
            height: 11px;
            border-radius: 50px;

            &:not(:first-child) {
                @apply ml-3;
            }
        }

        &__arrow-left {
            transform: rotate(-180deg);
            left: 0;
        }

        &__arrow-right {
            right: 0;
        }

        &__arrow-container {
            @apply w-full mx-auto absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 1376px;
        }
    }
</style>

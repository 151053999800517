<template>
    <div class="home-video mt-10">
        <div class="home-video__container container mx-auto px-container lg:px-container-lg">
            <h2 class="text-brand-purple text-center">{{ heading }}</h2>
        </div>

        <div ref="image" class="home-video__image relative overflow-hidden mt-3">
            <picture v-if="imageDesktop">
                <source v-if="imageMobile" media="(max-width : 991px)" :data-srcset="imageMobile.url"/>
                <source media="(min-width : 992px)" :data-srcset="imageDesktop.url"/>
                <img :src="imageDesktop.url" :alt="imageDesktop.alt" ref="background" class="w-full" loading="eager"/>
            </picture>


            <div class="home-video__play absolute cursor-pointer" @click="toggleVideo">
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
                    <g fill="none" fill-rule="evenodd">
                        <circle cx="40" cy="40" r="40" fill="#FFF"/>
                        <polygon fill="#29BACC" points="33 26 33 54 55 40"/>
                    </g>
                </svg>
            </div>

            <div class="home-video__buttons flex flex-col lg:flex-row justify-center items-center absolute w-full">
                <a :href="buttonOne.url" :target="buttonOne.target" class="btn btn--blue">{{buttonOne.label}}</a>
                <a :href="buttonTwo.url" :target="buttonTwo.target" class="btn btn--ghost btn--no-chevron mt-2 lg:mt-0 lg:ml-5">{{buttonTwo.label}}</a>
            </div>

            <div ref="video" class="home-video__video w-full absolute lg:flex lg:justify-center">
                <video ref="playerSmall" controls class="w-full md:hidden">
                    <source type="video/mp4" :src="video.sm">
                </video>
                <video ref="playerMedium" controls class="w-full hidden md:block lg:hidden">
                    <source type="video/mp4" :src="video.md">
                </video>
                <video ref="playerLarge" controls class="hidden home-video__desktop-video lg:block">
                    <source type="video/mp4" :src="video.lg">
                </video>

                <div class="home-video__close flex justify-center items-center absolute top-0 right-0 cursor-pointer" @click="toggleVideo">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                        <path fill="#FFF" fill-rule="evenodd" d="M16.9705627,-7.81597009e-14 L18.3847763,1.41421356 L10.606,9.192 L18.3847763,16.9705627 L16.9705627,18.3847763 L9.192,10.606 L1.41421356,18.3847763 L-8.30446822e-14,16.9705627 L7.778,9.192 L-8.30446822e-14,1.41421356 L1.41421356,-7.7826634e-14 L9.192,7.778 L16.9705627,-7.81597009e-14 Z"/>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'home-video',

        props : {
            heading : {type : String, default : ''},
            imageDesktop : {type : Object, default : null},
            imageMobile : {type : Object, default : null},
            video : {type : Object, default : () => {}},
            buttonOne : {type : Object, default : () => {}},
            buttonTwo : {type : Object, default : () => {}},
        },

        data(){
            return {
                videoOpened : false,

                blockHeight : 0,

                currentPlayer : null,

                currentTimePlaying : 0
            }
        },

        watch : {
            currentPlayer(){
                // Pause on all player
                this.$refs.playerSmall.pause();
                this.$refs.playerMedium.pause();
                this.$refs.playerLarge.pause();

                // Set the new player at the current time playing
                this.currentPlayer.currentTime = this.currentTimePlaying;

                if(this.videoOpened) {
                    // Start player
                    this.currentPlayer.play();
                }
            }
        },

        mounted(){
            if(window.innerWidth < 768) {
                this.currentPlayer = this.$refs.playerSmall;
            }
            else if (window.innerWidth < 992) {
                this.currentPlayer = this.$refs.playerMedium;
            }
            else {
                this.currentPlayer = this.$refs.playerLarge;
            }

            window.addEventListener('resize', this.resizeHandler);
        },

        beforeDestroy(){
            window.removeEventListener('resize', this.resizeHandler);
        },

        methods:  {
            toggleVideo(){
                if(!this.videoOpened){
                    this.blockHeight = this.$refs.image.clientHeight;
                }

                TweenLite.to(this.$refs.image, 0.4, {
                    height : (!this.videoOpened) ? this.$refs.video.clientHeight : this.blockHeight,
                    ease : Expo.easeOut,
                    onComplete : () => {
                        if(!this.videoOpened) {
                            TweenLite.set(this.$refs.image, {
                                height : 'auto'
                            });
                        }
                    }
                });

                TweenLite.to(this.$refs.background, 0.4, {
                    opacity : (!this.videoOpened) ? 0 : 1
                });

                TweenLite.to(this.$refs.video, 0.8, {
                    y : (!this.videoOpened) ? '-100%' : '0%',
                    x: '-50%',
                    ease : Expo.easeOut
                });

                if(!this.videoOpened) {
                    this.currentPlayer.play();
                } else {
                    this.currentPlayer.pause();
                }

                this.videoOpened = !this.videoOpened;
            },

            resizeHandler(){
                // Save the timer
                this.currentTimePlaying = this.currentPlayer.currentTime;

                // Watch change on window width to update the current player
                if(window.innerWidth < 768) {
                    this.currentPlayer = this.$refs.playerSmall;
                }
                else if (window.innerWidth < 992) {
                    this.currentPlayer = this.$refs.playerMedium;
                }
                else {
                    this.currentPlayer = this.$refs.playerLarge;
                }

                if(this.videoOpened) {
                    TweenLite.set(this.$refs.image,  {
                        height : this.$refs.video.clientHeight,
                    });
                }
            }
        }
    }
</script>

<style lang="scss">
    .home-video {
        &__play {
            top: 19%;
            left: 50%;
            transform: translateX(-50%);
            transition: all 0.3s ease-out;

            circle {
                transition: all 0.3s ease-out;
            }

            @screen lg {
                top: 30%;
            }

            &:hover {
                transform: scale(1.1) translateX(-40%);

                circle {
                    fill: #f2f4f7;
                }
            }
        }

        &__buttons {
            bottom: 20%;
            left: 50%;
            transform: translateX(-50%);

            @screen lg {
                bottom: 161px;
            }
        }

        &__video {
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
        }

        &__desktop-video {
            @screen lg {
                height: 80vh;
                width: 80vw;
            }
        }

        &__close {
            width: 100px;
            height: 100px;
        }

        @media (max-width : 620px) {
            &__play {
                top: 5%;
            }

            &__buttons {
                bottom: 5%;
            }
        }
    }
</style>

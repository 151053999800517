<template>
    <div class="iframe-parametrable">
        <iframe :class="params.p" ref="iframe" :style="'height : ' + iframeHeight+'px'" class="w-full" @load="iframeLoaded"></iframe>
    </div>
</template>

<script>
    export default {
        name : 'iframe-parametrable',

        props : {
            href : { type : String, default : ''},
            params : { type : Object, default : () => {}},
            iframeHeight : { type : Number, default : null}
        },

        data(){
            return {
                iframeSrc : this.href
            }
        },

        created(){
            this.refreshHref();
        },

        mounted(){
            this.loadIframe();

            var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
            var eventer = window[eventMethod];
            var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

            eventer(messageEvent, function (e) {
                if (e.origin !== 'https://rc.dataviv.net' && e.origin !== 'https://app.dataviv.net') {
                    return;
                }

                document.location = event.data;
            });
        },

        methods : {
            refreshHref(){
                // While we got things to replace
                while(this.iframeSrc.indexOf('{') != -1) {
                    let start_pos = this.iframeSrc.indexOf('{') + 1;
                    let end_pos = this.iframeSrc.indexOf('}',start_pos);
                    let text_to_get = this.iframeSrc.substring(start_pos,end_pos);

                    // If we found the element to replace
                    if(text_to_get) {
                        // Replace
                        this.iframeSrc = this.iframeSrc.replace('{'+text_to_get+'}', encodeURIComponent(this.params[text_to_get]));
                    }
                }
            },

            loadIframe(){
                this.$refs.iframe.setAttribute('src', this.iframeSrc);
            },

            iframeLoaded(){
                this.$emit('iframe-loaded');
            }
        }
    }
</script>

<style lang="scss">
    iframe {
        @apply px-4;
        min-height : 600px;


        @media(min-width : 768px) {
            @apply px-0;
            min-height : 480px;
        }
    }
</style>

<template>
    <div class="testimonial-carousel overflow-hidden pt-5 lg:pt-12 pb-3">
        <div class="testimonial-carousel__container container mx-auto px-container lg:px-container-lg">
            <h2 class="text-brand-purple text-center">{{ heading }}</h2>
            <div class="testimonial-carousel__container relative flex-no-wrap mt-6 lg:mt-8">
                <div ref="slidesContainer" class="testimonial-carousel__slide-container flex" v-touch:swipe="swipeHandler">
                    <div ref="slide" class="testimonial-carousel__slide w-full lg:w-1/2 flex-shrink-0 flex flex-col justify-center items-center px-2 py-4 lg:px-4 lg:py-5 bg-white shadow-medium" v-for="(item, key) in testimonials" :key="'slide' + key">
                        <img :src="item.image.url" :alt="item.image.alt" class="testimonial-carousel__image" />
                        <p class="text-grey-dark text-center max-w-full mt-5" v-html="item.description" />
                        <div class="testimonial-carousel__sep bg-brand-purple my-3 lg:mt-4"></div>
                        <p class="text-brand-purple max-w-full">{{ item.name }}</p>
                    </div>
                </div>
                <div ref="arrowLeft" class="testimonial-carousel__arrow-left arrow-blue absolute hidden lg:block cursor-pointer" @click="currentIndex--"></div>
                <div ref="arrowRight" class="testimonial-carousel__arrow-right arrow-blue absolute hidden lg:block cursor-pointer" @click="currentIndex++"></div>
            </div>
            <div v-if="testimonials.length > 1" class="testimonial-carousel__dots flex justify-center mt-4 lg:mt-5">
                <div class="testimonial-carousel__dot rounded-full" :class="{'bg-brand-blue' : currentIndex === index, 'bg-grey-medium' : currentIndex != index}" v-for="(item, index) in testimonials" :key="'dot' + index" @click="currentIndex = index"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import Carousel from '../mixins/Carousel';

    export default {
        name:  'testimonial-carousel',

        props:  {
            heading : { type : String, default : ''},
            testimonials : { type : Array, default : null}
        },

        mixins : [Carousel],

        mounted(){
            if(this.slideCount >= 2) {
                this.currentIndex = 1;

                this.updateSlide();
            }
        }
    }
</script>

<style lang="scss">
    .testimonial-carousel {
        &__slide {
            opacity: 0.4;
            transform: scale(0.9);
            transition: opacity 0.3s ease-out;

            @screen lg{
                &:first-child {
                    @apply ml-3/12;
                }
            }

            &:not(:first-child) {
                @apply ml-8;
            }

            &.current {
                opacity: 1;
                transform: scale(1);
            }
        }

        &__sep {
            width: 30px;
            height: 2px;
        }

        &__dot {
            width: 12px;
            height: 12px;

            &:not(:first-child) {
                @apply ml-3;
            }
        }

        &__arrow-left {
            transform: translateY(-50%) rotate(-180deg);
            left: calc(25% - 116px);
        }

        &__arrow-right {
            right: calc(25% - 116px);
            transform: translateY(-50%);
        }

        &__arrow-left,
        &__arrow-right {
            top: 50%;
        }
    }
</style>

<template>
    <div class="page-bottom relative">
        <intersect @enter="enter" @leave="leave">
            <div class="page-bottom__top w-full absolute">
                <div class="container mx-auto px-container lg:px-container-lg flex justify-end">
                    <a href="#header" ref="backtop" class="page-bottom__back-to-top p-2 pt-0 cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="30" viewBox="0 0 16 30">
                            <path fill="#29BACC" fill-rule="evenodd" d="M1322,3685 L1330,3693.2955 L1328.54545,3694.9115 L1323.043,3689.206 L1323.04348,3715 L1320.95652,3715 L1320.956,3689.206 L1315.45455,3694.9115 L1314,3693.2955 L1322,3685 Z" transform="translate(-1314 -3685)"/>
                        </svg>
                    </a>
                </div>
            </div>
        </intersect>

        <div class="page-bottom__links lg:hidden">
            <div ref="links" class="w-full flex flex-grow fixed bottom-0 left-0 right-0">
                <a
                    v-for="(item, index) in links"
                    :key="index"
                    :href="item.link.url"
                    class="page-bottom__link font-raleway font-bold text-small uppercase text-white text-center flex-grow"
                    :class="{ 'bg-brand-blue' : item.backgroundColor === 'blue', 'bg-grey-button' : item.backgroundColor === 'gray'}"
                >
                    {{ item.link.label}}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { TweenLite } from 'gsap';
    import Intersect from 'vue-intersect';
    import ScrollToPlugin from "gsap/ScrollToPlugin";

    export default {
        name : 'page-bottom',

        props:  {
            links : { type : Array, default: () => []}
        },

        components : { Intersect },

        methods : {

            enter(){
                TweenLite.set(this.$refs.links, {
                    position : 'relative',
                    bottom : 'inherit',
                    left : 'inherit',
                    right : 'inherit'
                });
            },
            leave(){
                TweenLite.set(this.$refs.links, {
                    position : 'fixed',
                    bottom : 0,
                    left : 0,
                    right : 0
                });
            }
        }
    }
</script>

<style lang="scss">
    .page-bottom {
        &__top {
            top: -50px;
        }

        &__links {
            height: 51px;
        }

        &__link {
            padding: 22px 0 20px;
            letter-spacing: 1px;
            flex: 1 0 50%;
        }
    }
</style>

<template>
    <nav class="menu-component">
        <div class="menu-component__burger-menu relative z-2 lg:hidden" @click="toggleMenu">
            <div ref="topbar" class="menu-component__burger-menu-bar bg-white"></div>
            <div ref="middlebar" class="menu-component__burger-menu-bar bg-white"></div>
            <div ref="bottombar" class="menu-component__burger-menu-bar bg-white"></div>
        </div>
        <div ref="menuList" class="menu-component__list-container absolute top-0 left-0 right-0 z-0 lg:relative lg:inset-auto lg:z-1 flex items-center w-full bg-grey-light lg:bg-transparent px-2 py-1 lg:p-0">
            <ul class="menu-component__nav-list flex flex-col items-start lg:flex-row lg:items-center w-full lg:w-auto">
                <li class="w-full lg:w-auto" v-for="({ title, url, target }, i) in menuItems" :key="i">
                    <a
                        :href="url"
                        :target="target"
                        class="display-navigation text-brand-purple lg:text-white block py-2 lg:py-0"
                    >{{ title }}</a>
                </li>
            </ul>
            <div v-if="rightLink" class="hidden lg:block">
                <a :href="rightLink.url" :target="rightLink.target" class="btn btn--white">{{ rightLink.title }}</a>
            </div>
        </div>
    </nav>
</template>

<script>
    import { TweenLite } from 'gsap';

    export default {
        name : 'menu-component',

        props : {
            menuItems: { type: Array, default: () => [] },
            rightLink:   { type: Object, default: null },
        },

        data(){
            return {
                isOpen : false,

                breakpoint : 992
            }
        },

        mounted(){
            // Add resize handler
            window.addEventListener('resize', this.resizeHandler);
        },

        beforeDestroy(){
            // Remove resize handler
            window.removeEventListener('resize', this.resizeHandler);
        },

        methods : {
            // On resize
            resizeHandler(){
                // If the menu is opened
                if(this.isOpen) {
                    // Close it
                    this.toggleMenu();
                }


                // If we passe the breakpoint
                if(window.innerWidth > this.breakpoint) {
                    // Reset transform on menu
                    TweenLite.set(this.$refs.menuList, {
                        y : 0
                    });
                } else {
                    // Hide the menu
                    TweenLite.set(this.$refs.menuList, {
                        y : -this.$refs.menuList.clientHeight
                    });
                }
            },

            toggleMenu(){
                // Burger animation
                if(!this.isOpen) {
                    this.burgerSwitchClose();
                } else {
                    this.burgerSwitchMenu();
                }

                var headerHeight = document.querySelector('header').clientHeight;

                TweenLite.to(this.$refs.menuList, 0.8, {
                    y : (!this.isOpen) ? headerHeight : -this.$refs.menuList.clientHeight,
                    ease : Expo.easeOut
                });

                this.isOpen = !this.isOpen;
            },

            burgerSwitchClose(){
                TweenLite.to(this.$refs.topbar, 0.2, {
                    y : 8
                });

                TweenLite.to(this.$refs.bottombar, 0.2, {
                    y : -8
                });

                TweenLite.to(this.$refs.middlebar, 0.2, {
                    opacity : 0,
                    delay : 0.2
                });

                TweenLite.to(this.$refs.topbar, 0.3, {
                    rotation : '-45deg',
                    delay : 0.4,
                    ease : Power3.easeOut
                });

                TweenLite.to(this.$refs.bottombar, 0.3, {
                    rotation : '45deg',
                    delay : 0.4,
                    ease : Power3.easeOut
                });
            },

            burgerSwitchMenu(){
                TweenLite.to([this.$refs.topbar, this.$refs.bottombar], 0.3, {
                    rotation : '0deg',
                    ease : Power3.easeOut
                });

                TweenLite.to(this.$refs.middlebar, 0.2, {
                    opacity : 1,
                    delay : 0.3
                });

                TweenLite.to([this.$refs.topbar, this.$refs.bottombar], 0.2, {
                    y : 0,
                    delay : 0.4
                });
            }
        }
    }
</script>

<style lang="scss">
    .menu-component {
        &__list-container {
            transform: translateY(-100%);
            margin-left: -15px;
            width: calc(100% + 30px);
            @screen lg {
                transform: translateY(0);
            }
        }

        &__nav-list {
            @screen lg {
                @apply mr-5;
            }
        }

        &__nav-list li {
            &:not(:first-child) {
                border-top: 1px solid #CECECE;
            }

            @screen lg {
                &:not(:first-child) {
                    border : none;
                }

                &:not(:first-child) {
                    @apply ml-4;
                }
            }
        }

        &__burger-menu-bar {
            width: 24px;
            height: 2px;
            margin : 6px 0;
        }
    }
</style>

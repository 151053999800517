<template>
    <section ref="hero" class="hero bg-brand-purple">
        <div class="hero__container container mx-auto px-container lg:px-container-lg flex flex-col lg:flex-row">
            <div
                class="hero__body lg:w-1/2 px-grid lg:px-grid-lg  mx-auto py-8 lg:py-12 flex-col justify-center px-grid lg:px-grid-lg">
                <h1 class="text-white">{{ heading }}</h1>
                <h5 v-if="subheading" class="text-white mt-2">{{ subheading }}</h5>

                <div v-if="list" class="hero__list mt-2">
                    <div v-for="(item, index) in list" :key="index" class="hero__list-item flex">
                        <div class="checkmark-blue mr-2 mt-1/2"></div>
                        <p class="text-white">{{ item.text }}</p>
                    </div>
                </div>
            </div>
            <div ref="formContainer" class="hero__form relative w-full lg:w-1/2 px-grid lg:px-grid-lg mt-6 lg:mt-12">
                <div ref="form" class="hero__form-content absolute bg-white w-full shadow-medium py-5 px-2 lg:p-4">
                    <h2 v-if="formHeading != ''" class="text-brand-purple text-center mb-5 lg:mb-6">{{ formHeading }}</h2>
                    <div class="hero__iframe">
                        <iframe-parametrable :href="iframe" :iframe-height="iframeHeight" :params="params" @iframe-loaded="iframeLoaded"></iframe-parametrable>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import IframeParametrable from "./IframeParametrable";

    export default {
        name : 'hero-form',

        components : { IframeParametrable },

        props : {
            heading : { type : String, default : ''},
            subheading : { type : String, default : ''},
            formHeading : { type : String, default : ''},
            iframe : { type : String, default : ''},
            iframeHeight : { type : String, default : ''},
            list : { type : Array, default : () => []},
            params : { type : Object, default : () => [] }
        },


        mounted(){
            window.addEventListener('resize', this.setBottomMargin);
        },

        beforeDestroy(){
            window.removeEventListener('resize', this.setBottomMargin);
        },

        methods : {
            iframeLoaded(){
                this.setBottomMargin();
            },

            setBottomMargin(){
                TweenLite.set(this.$refs.hero, {
                    marginBottom : this.$refs.form.clientHeight - this.$refs.formContainer.clientHeight + 100
                });
            },
        }
    }
</script>

<style lang="scss">
    .hero {
        &__list-item:not(:first-child) {
            @apply mt-1/2;
        }

        &__form {
            top: -70px;

            @screen lg {
                top: 0;
            }
        }

        &__form-content {
            left : 0;
        }
    }
</style>

<template>
    <div :class="{'justify-between' : slides.length > 1, 'justify-center' : slides.length <= 1}" class="slideshow-header-componenent flex items-center px-4 md:px-12">
        <div
            v-if="slides.length > 1"
            class="slideshow-header-component__arrow slideshow-header-component__arrow--left"
            @click.prevent="arrowClicked(-1)"
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <g stroke="#29BACC" stroke-width="2">
                        <path d="M6 12L0 6 6 0" transform="translate(-110 -111) translate(0 111) translate(110) translate(17 14)"/>
                    </g>
                </g>
            </svg>
        </div>
        <div ref="content" class="slideshow-header-component__content flex items-center">
            <slot></slot>
        </div>
        <div
            v-if="slides.length > 1"
            class="slideshow-header-component__arrow slideshow-header-component__arrow--right"
            @click.prevent="arrowClicked(1)"
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <g stroke="#29BACC" stroke-width="2">
                        <path d="M6 12L0 6 6 0" transform="translate(-110 -111) translate(0 111) translate(110) translate(17 14)"/>
                    </g>
                </g>
            </svg>

        </div>
    </div>
</template>

<script>
    export default {
        name : 'slideshow-header',

        props : {
            autoplaySpeed : {type : Number, default : 0},
            slides : { type : Array, default : () => []}
        },

        data(){
            return {
                currentIndex : 0,

                maxheight : 0,
            }
        },

        methods : {
            updateMaxHeight(){
                Array.prototype.forEach.call(this.$refs.content.querySelectorAll('.slideshow-header__slide'), (el, i) => {
                    if(el.clientHeight > this.maxheight)
                    {
                        this.maxheight = el.clientHeight;
                    }
                });

                TweenLite.set(this.$refs.content,  {
                    height : this.maxheight
                });
            },

            arrowClicked(pDirection){
                this.currentIndex += pDirection;
                if(this.currentIndex < 0) this.currentIndex = this.slides.length - 1;
                if(this.currentIndex > this.slides.length -1) this.currentIndex = 0;

                this.updateSlide();
            },

            updateSlide(){
                TweenLite.set(this.$refs.content.querySelectorAll('.slideshow-header__slide'),  {
                    opacity : 0,
                    display : 'none'
                });

                TweenLite.set(this.$refs.content.querySelectorAll('.slideshow-header__slide')[this.currentIndex],  {
                    opacity : 1,
                    display : 'flex'
                });


            }
        },

        mounted(){
            this.updateMaxHeight();

            window.addEventListener('resize', this.updateMaxHeight);

            if(this.autoplaySpeed > 0) {
                setInterval(() => {
                    this.arrowClicked(1);
                }, this.autoplaySpeed * 1000)
            }
        }
    }
</script>

<style lang="scss">
    .slideshow-header-component {

        &__content {
            position : relative;

            > *:not(:first-child) {
                display : none;
                opacity : 0;
            }
        }

        &__arrow {
            &--right{
                transform : rotate(180deg);
            }
        }
    }
</style>

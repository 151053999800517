export default {
    data() {
        return {
            currentIndex: 0,

            slideCount: 0
        }
    },

    watch : {
        currentIndex(){
            if(this.currentIndex < 0) {
                this.currentIndex = this.slideCount - 1;
            }
            if(this.currentIndex >= this.slideCount) {
                this.currentIndex = 0;
            }

            this.updateSlide();
        }
    },

    mounted(){
        this.slideCount = this.$refs.slide.length;

        TweenLite.set(this.$refs.slide[this.currentIndex], {
            className : '+=current'
        });

        window.addEventListener('resize', this.resizeHandler);
    },

    beforeDestroy(){
        window.removeEventListener('resize', this.resizeHandler);
    },

    methods: {
        swipeHandler(pDirection){
            switch(pDirection) {
                case 'left' :
                    this.currentIndex++;
                    break;
                case 'right' :
                    this.currentIndex--;
                    break;
            }
        },

        updateSlide(){
            let slideStyle = this.$refs.slide[1].currentStyle || window.getComputedStyle(this.$refs.slide[1]);

            TweenLite.to(this.$refs.slidesContainer, 0.6, {
                x : - this.currentIndex * (this.$refs.slide[0].clientWidth + parseInt(slideStyle.marginLeft)),
                ease : Expo.easeOut
            });

            TweenLite.set(this.$refs.slide, {
                className : '-=current'
            });
            TweenLite.set(this.$refs.slide[this.currentIndex], {
                className : '+=current'
            });
        },

        resizeHandler(){
            let slideStyle = this.$refs.slide[1].currentStyle || window.getComputedStyle(this.$refs.slide[1]);

            TweenLite.set(this.$refs.slidesContainer, {
                x : - this.currentIndex * (this.$refs.slide[0].clientWidth + parseInt(slideStyle.marginLeft))
            });
        }
    }
}

import "./assets/styles/main.scss";

import Vue from "vue";
import MenuComponent from "@/app/components/MenuComponent";
import HomeVideo from "@/app/components/HomeVideo";
import TestimonialCarousel from "@/app/components/TestimonialCarousel";
import Carousel from "@/app/components/Carousel";
import CollapsePanels from "@/app/components/CollapsePanels";
import PageBottom from "@/app/components/PageBottom";
import HeroForm from "@/app/components/HeroForm";
import CsrfInput from "@/app/components/CsrfInput";
import SlideshowHeader from "@/app/components/SlideshowHeader";
import IframeParametrable from "@/app/components/IframeParametrable";

import 'picturefill';

import Vue2TouchEvents from 'vue2-touch-events';
Vue.use(Vue2TouchEvents);

// KSS FIX !!!
// Change les délimiteur de template de Vue.js pour que les délimiteurs {{ }} ne soit pas interprété par Vue.js dans KSS.
// Dans les fichiers *.vue, les délimiteurs ne sont pas changés.
Vue.options.delimiters = ["||", "||"];

new Vue({
    el: "#app",
    components: {
        MenuComponent,
        HomeVideo,
        TestimonialCarousel,
        Carousel,
        CollapsePanels,
        PageBottom,
        HeroForm,
        SlideshowHeader,
        CsrfInput,
        IframeParametrable
    }
});
